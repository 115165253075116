import cx from 'classnames';
import React from 'react';

type Props = {
  name: string;
  assetsFolder?: string;
  size?: number;
  fill?: string;
  className?: string;
  height?: number;
  width?: number;
} & React.HTMLAttributes<SVGElement>;

const SVG: React.FunctionComponent<Props> = ({
  name,
  assetsFolder = 'Icons',
  size,
  fill = 'currentColor',
  className,
  height,
  width,
  ...rest
}) => {
  let Source;

  try {
    Source = require(`src/assets/${assetsFolder}/${name}.svg`);
  } catch (err) {
    console.error(`Could not find icon: ${name}.svg in src/assets/${assetsFolder}.`);
  }

  const widthAttribute = width || size;
  const heightAttribute = height || size;
  const dimensions = {
    ...(heightAttribute && { height: heightAttribute }),
    ...(widthAttribute && { width: widthAttribute }),
  };

  return Source ? (
    <Source className={cx('align-middle flex-shrink-0', className)} fill={fill} {...dimensions} {...rest} />
  ) : null;
};

export default SVG;
